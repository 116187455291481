import { $$ } from '../../tools/utils'
import FakeSelect from './fakeSelect'

export default class FakeSelectController {
  selectsMemory = []

  init () {
    this.setupSelects()
  }

  setupSelects () {
    this.selectsMemory = [...$$('.select')]
    this.selectsMemory.forEach(
      (select) => new FakeSelect(select)
    )
  }

  refresh () {
    this.setupSelects()
  }
}
