import { fabric } from 'fabric'
import { $, pubsub } from '../../tools/utils'
import background from '../../../images/img-cartel_bkg.jpg'

export default class Canvas {
  constructor () {
    this.DOM = {
      cartelContainer: $('.action__cartel')
    }

    this.CONTAINER_START_WIDTH = this.DOM.cartelContainer.clientWidth
    this.SCALE_FACTOR = 2
    this.WIDTH = 1224
    this.HEIGHT = 1584
    this.WRAPPER_PADDING = 54 * this.SCALE_FACTOR
    this.PADDING_SPACE = 14 * this.SCALE_FACTOR
    this.BODY_SAFE_AREA = 243 * this.SCALE_FACTOR
    this.CAUSES_SAFE_AREA = 63 * this.SCALE_FACTOR
    this.CAUSES_TOP = 411 * this.SCALE_FACTOR

    this.isFirstRender = true
  }

  init () {
    this.setupCanvas()
    if (this.isFirstRender) this.isFirstRender = false
    pubsub.publish('scroll:update')
  }

  setupCanvas () {
    const CONTENT_WIDTH = this.WIDTH - (this.WRAPPER_PADDING * 2) - 32

    this.canvas = new fabric.StaticCanvas('cartel', {
      width: this.WIDTH,
      height: this.HEIGHT
    })
    this.canvas.setBackgroundImage(
      background,
      this.canvas.renderAll.bind(this.canvas)
    )

    this.headerCopy = new fabric.Textbox('EN EL MUNICIPIO DE MAPÍMI:', {
      width: CONTENT_WIDTH,
      top: this.WRAPPER_PADDING + 70 * this.SCALE_FACTOR,
      left: this.WRAPPER_PADDING,
      fontFamily: 'Raleway',
      fontWeight: '900',
      fontSize: 22 * this.SCALE_FACTOR,
      fill: '#000'
    })
    this.canvas.add(this.headerCopy)

    this.problemCopy = new fabric.Textbox('El pozo 1 (Colegio de Bachilleres) del Sistema de Agua Potable de José María Morelos y Pavón durante 2018 y 2019 excedió los niveles de riesgo* de arsénico y/o fluoruro.', {
      width: CONTENT_WIDTH,
      left: this.WRAPPER_PADDING,
      fontFamily: 'Merriweather',
      fontSize: 24 * this.SCALE_FACTOR,
      fill: '#000'
    })
    this.canvas.add(this.problemCopy)

    this.causesCopy = new fabric.Textbox('Su consumo puede causarte distintos tipos de cáncer, enfermedades graves de la piel, gangrena y daños en los huesos.', {
      width: 450 * this.SCALE_FACTOR,
      top: this.CAUSES_TOP,
      left: this.WRAPPER_PADDING + 55 * this.SCALE_FACTOR,
      fontFamily: 'Raleway',
      fontWeight: 500,
      fontSize: 18 * this.SCALE_FACTOR,
      fill: '#000'
    })
    this.canvas.add(this.causesCopy)

    this.calculateBodyContentVerticalPosition()
    this.resize()
  }

  getImageFile () {
    return this.canvas.toDataURL({
      format: 'jpg',
      multiplier: this.getMultiplier()
    })
  }

  getMultiplier () {
    const { cartelContainer } = this.DOM
    return this.WIDTH / cartelContainer.offsetWidth
  }

  calculateBodyContentVerticalPosition () {
    const topSpace = (124 * this.SCALE_FACTOR) + (this.BODY_SAFE_AREA - (this.headerCopy.height + this.PADDING_SPACE + this.problemCopy.height)) / 2
    this.headerCopy.set({
      top: topSpace
    })

    const headerCopyBottom = this.headerCopy.top + this.headerCopy.height
    this.problemCopy.set({
      top: headerCopyBottom + this.PADDING_SPACE
    })

    this.causesCopy.set({
      top: this.CAUSES_TOP + ((this.CAUSES_SAFE_AREA - this.causesCopy.height) / 2)
    })
  }

  render (data) {
    const { header, body, causes } = this.getContentFromData(data)
    const { nameRangeIndex, excedeRangeIndex } = body
    const { causesRangeIndex } = causes

    this.headerCopy.set({ text: header })
    this.problemCopy.set({
      text: body.text,
      styles: {
        0: {
          ...this.getStylesFromRange(nameRangeIndex, { fontWeight: 'bold' }),
          ...this.getStylesFromRange(excedeRangeIndex, {
            fontWeight: 900,
            fill: '#c0dfff',
            textBackgroundColor: '#000'
          })
        }
      }
    })
    this.causesCopy.set({
      text: causes.text,
      styles: {
        0: {
          ...this.getStylesFromRange(causesRangeIndex, { fontWeight: 900 })
        }
      }
    })

    this.calculateBodyContentVerticalPosition()

    this.canvas.renderAll()
  }

  getContentFromData ({
    nombre,
    anios,
    excede_AS: excedeAS,
    excede_FL: excedeFL,
    pozos
  }) {
    const aniosCopy = 'durante ' + (anios[0] + (anios[1] ? ` y ${anios[1]}` : ''))
    const pozosCopy = pozos.length > 1
      ? `${pozos.length} pozos`
      : `El pozo ${pozos[0].nombre}`
    const excedeCopy = ` ${pozos.length > 1 ? 'excedieron' : 'excedió'} los niveles de riesgo* de ${excedeAS ? 'arsénico' : ''}${excedeAS && excedeFL ? ' y/o ' : ''}${excedeFL ? 'fluoruro' : ''}. `
    const causesCopy = ({
      '01': 'daños en los huesos.',
      11: 'distintos tipos de cáncer, enfermedades graves de la piel, gangrena y daños en los huesos.',
      10: 'distintos tipos de cáncer, enfermedades graves de la piel y gangrena.'
    })[`${+excedeAS}${+excedeFL}`]

    const nameRangeStart = pozos.length > 1 ? 0 : 8
    const nameRangeEnd = pozos.length > 1
      ? pozosCopy.length
      : nameRangeStart + pozos[0].nombre.length
    const excedeRangeStart = pozosCopy.length + 1 + aniosCopy.length + 1
    const excedeRangeEnd = excedeRangeStart + excedeCopy.length
    const causesRangeStart = !excedeAS && excedeFL ? 26 : 45

    return {
      header: `EN EL MUNICIPIO DE ${nombre.toUpperCase()}:`,
      body: {
        text: `${pozosCopy} ${aniosCopy} ${excedeCopy}`,
        nameRangeIndex: [nameRangeStart, nameRangeEnd],
        excedeRangeIndex: [excedeRangeStart, excedeRangeEnd]
      },
      causes: {
        text: `Su consumo puede causarte ${causesCopy}`,
        causesRangeIndex: [
          causesRangeStart,
          causesRangeStart + causesCopy.length
        ]
      }
    }
  }

  getStylesFromRange (range, styles) {
    const resultObj = {}

    for (let i = range[0]; i <= range[1]; i++) {
      resultObj[i] = styles
    }

    return resultObj
  }

  resize () {
    const {
      clientWidth: containerResizedWidth
    } = this.DOM.cartelContainer
    const containerWidth = this.isFirstRender
      ? this.CONTAINER_START_WIDTH
      : containerResizedWidth
    const ratio = this.canvas.getWidth() / this.canvas.getHeight()
    const scale = containerWidth / this.canvas.getWidth()
    const zoom = this.canvas.getZoom() * scale

    this.canvas.setDimensions({
      width: containerWidth,
      height: containerWidth / ratio
    })
    this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  }
}
