import { $, $find } from './utils'

export default class Loader {
  loading (refString, feedback) {
    const ref = $(refString)

    ref.classList.remove('error')
    ref.classList.add('loading')
    $find(ref, '.loader__feedback')
      .textContent = feedback || ''
  }

  loaded (refString, feedback, state) {
    const ref = $(refString)

    ref.classList.remove('loading')

    if (state === 'error') {
      ref.classList.add(state || '')
      $find(ref, '.loader__feedback')
        .textContent = feedback || ''
    }
  }
}
