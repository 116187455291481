import { $find, pubsub } from '../../tools/utils'

export default class FakeSelect {
  constructor (container) {
    this.DOM = {
      container,
      select: $find(container, 'select'),
      label: $find(container, 'label')
    }

    this.init()
  }

  init () {
    this.defaultSetup()
    this.bindEvents()
  }

  defaultSetup () {
    const { defaultCopy } = this.DOM.label.dataset
    this.DOM.label.textContent = defaultCopy
  }

  bindEvents () {
    const { container, select } = this.DOM
    const { resetEvent } = container.dataset

    select.addEventListener('change', (e) => this.changeHandler(e))
    if (resetEvent !== undefined) {
      pubsub.suscribe(resetEvent, () => this.resetHandler())
    }
  }

  changeHandler ({ target }) {
    const { defaultCopy } = this.DOM.label.dataset
    this.DOM.label.textContent = target.value || defaultCopy
  }

  resetHandler () {
    this.defaultSetup()
  }
}
