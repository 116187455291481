import { debounce } from 'lodash'
import ModuleManager from './moduleManager'
import Cartel from './modules/cartel'
import FakeSelectController from './modules/fakeSelect'
import Intro from './modules/intro'

window.addEventListener('load', () => {
  /**
   * Module manager setup
   */
  const moduleCatalogSetup = {
    general: [
      new Cartel(),
      new FakeSelectController()
    ]
  }

  const moduleManager = new ModuleManager(moduleCatalogSetup)
  moduleManager.init()

  /**
   * Bind events global control
   */
  window.onresize = debounce(() => {
    moduleManager.resizeHandler()
  }, 100)
})
