import FontFaceObserver from 'fontfaceobserver'
import { loader } from '../../tools/utils'
import Form from './form'

export default class Cartel {
  init () {
    this.fetch()
  }

  fetch () {
    const merriweatherFont = new FontFaceObserver('Merriweather')
    const ralewayFont = new FontFaceObserver('Raleway')

    Promise.all([
      window.fetch('/pozosJSON.php'),
      merriweatherFont.load(),
      ralewayFont.load()
    ])
      .then(([res]) => res.json())
      .then(res => this.fetchSuccess(res))
      .catch(() => this.fetchFail())
  }

  fetchSuccess (res) {
    loader.loaded('.action__loader')

    // setup form component
    this.form = new Form({ data: res })
    this.form.init()
  }

  fetchFail () {
    loader.loaded(
      '.action__loader',
      'Lo sentimos, ocurrió un error al consultar la información. Intenta mas tarde o recarga la página para intentar nuevamente.',
      'error'
    )
  }

  resize () {
    if (this.form !== undefined) this.form.resize()
  }
}
